import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Container from "../components/container";
import Body from "../components/body";
import PageTitle from "../components/page-title";
import Paragraphs from "../components/paragraphs";
import ButtonGrid from "../components/button-grid";
import homeImage from "../images/feature-img.jpg";
import FadeWrapper from "../components/fade-wrapper";
import SpaceSafeImage from "../components/space-safe-image";

const IndexPage = ({ transitionStatus, entry, exit }) => {
  const paragraphs = [
    `Halo Hair Derbyshire is a mobile hairdressing service that 
    covers the Derby/Derbyshire area.`,
    `If you are looking for a truly relaxing and comfortable experience then 
    look no further, Leanne can provide advice on new colours and cutting styles 
    and pays special attention to giving a service that will keep your hair 
    healthy.`,
    `If you live in the Derby/Derbyshire area then why not
    be pampered in the comfort of your own home by a friendly and
    professional stylist who puts your needs first.`,
  ];
  return (
    <Layout transitionStatus={transitionStatus}>
      <Seo
        title="Home"
        description="Halo Hair Derbyshire is a mobile hairdressing service that covers the Derby/Derbyshire area. If you are looking for a truly relaxing and comfortable experience then look no further."
      />
      <FadeWrapper
        transitionStatus={transitionStatus}
        entry={entry}
        exit={exit}
      >
        <SpaceSafeImage
          src={homeImage}
          alt="Halo Hair Derbyshire Wedding Styling"
          height={650}
          width={1440}
          imageClasses="-z-10 w-full h-full object-cover inset-0"
          wrapperClasses="min-h-1/2-screen lg:min-h-0"
        />
        <div className="blue-brush-background bg-repeat-x bg-top w-full h-10 -mt-8" />
      </FadeWrapper>
      <Container transitionStatus={transitionStatus} entry={entry} exit={exit}>
        <Body>
          <PageTitle title="Bringing the hair salon to your door" />
          <Paragraphs paragraphs={paragraphs} />
          <ButtonGrid
            buttons={[
              {
                label: "See my prices",
                link: "services",
              },
              {
                label: "Book an appointment",
                link: "contact",
              },
            ]}
          />
        </Body>
      </Container>
    </Layout>
  );
};

export default IndexPage;
