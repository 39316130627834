import * as React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { EXITING } from "../constants/transition-states";

const initialState = { opacity: 0 };

const FadeWrapper = ({ children, transitionStatus, entry, exit }) => (
  <motion.div
    initial={entry.state.opacity ? entry.state : initialState}
    animate={transitionStatus === EXITING ? exit.state : { opacity: 1 }}
    transition={
      transitionStatus === EXITING
        ? { duration: exit.length }
        : { duration: 0.4 }
    }
  >
    {children}
  </motion.div>
);

FadeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  transitionStatus: PropTypes.string.isRequired,
  entry: PropTypes.instanceOf(Object).isRequired,
  exit: PropTypes.instanceOf(Object).isRequired,
};

export default FadeWrapper;
